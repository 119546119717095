import React, { useState } from 'react';
import { Modal, Input, Col, Row } from 'antd';
import { editPrizes } from '../../../services/testApi';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
interface EditMaterialPrizesModalbProps {
  materialId: number;
  managerId: string; //1 id or string of ids per semicolon
  onSavePrize: (materialId: number, managerId: string, prizes: string) => void
  prizes?: any;
}

const EditMaterialPrizesModal: React.FC<EditMaterialPrizesModalbProps> = ({ materialId, managerId, onSavePrize, prizes }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const defaultPrizesArr = prizes.split('; ');
  const [currentPrizes, setCurrentPrizes] = useState<any>({ 0: defaultPrizesArr[0], 1: defaultPrizesArr[1], 2: defaultPrizesArr[2] });
  const editPrice = async () => {
    const prizes = Object.values(currentPrizes).join('; ');
    await editPrizes(materialId, managerId, prizes);
    onSavePrize(materialId, managerId, prizes)
    setIsModalOpen(false);
  };

  return (
    <>
      <Link style={{ float: 'right', fontSize: 18 }} to='#' onClick={() => setIsModalOpen(true)}><EditOutlined /></Link>
      <Modal title={'Изменить цены'} open={isModalOpen} onOk={editPrice} onCancel={() => setIsModalOpen(false)}>
        <Row>
          <Col>
            За первый успех:
            <Input defaultValue={defaultPrizesArr[0]} onChange={(e) => setCurrentPrizes({ ...currentPrizes, 0: e.target.value })} />
          </Col>
          <Col>
            За второй успех:
            <Input defaultValue={defaultPrizesArr[1]} onChange={(e) => setCurrentPrizes({ ...currentPrizes, 1: e.target.value })} />
          </Col>
          <Col>
            За третий успех и более:
            <Input defaultValue={defaultPrizesArr[2]} onChange={(e) => setCurrentPrizes({ ...currentPrizes, 2: e.target.value })} />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditMaterialPrizesModal;