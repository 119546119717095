import { removeTokens } from '../hooks/auth';
const BASE_URL = '/api';

const getToken = (): string | null => {
  return localStorage.getItem('authToken');
};

const handleResponse = (response: Response, responseType: string) => {
  if (response.ok) {
    return responseType === 'blob' ? response.blob() : response.json()
  } else {
    if(response.status === 403) {
      removeTokens();
      window.location.replace('https://liderprodag.ru/')
    } else throw new Error('Request failed');    
  }
};

export const get = async <T>(path: string): Promise<T> => {
  const token = getToken();
  const url = `${BASE_URL}${path}`;

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await handleResponse(response, 'json');
    return data as T;
  } catch (error) {
    throw new Error('Error occurred during GET request');
  }
};

export const post = async <T>(path: string, body: any, isJson = 1, responseType = 'json'): Promise<T> => {
  const token = getToken();
  const url = `${BASE_URL}${path}`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...(isJson?{'Content-Type': 'application/json'}:null),
        Authorization: `Bearer ${token}`,
      },
      body: isJson?JSON.stringify(body):body,
    });
    const data = await handleResponse(response, responseType);
    return data as T;
  } catch (error) {
    throw new Error('Error occurred during POST request');
  }
};