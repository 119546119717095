import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Button, Table, Spin, Select, Modal, TreeSelect, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { materialListForAdmin, deleteMaterial, publishMaterial, saveMaterialAccess } from '../../../services/materialsApi';
import { getAllStates, getManagerList, handlePrizeRequest } from '../../../services/adminApi';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import EditMaterialPrizesModal from './EditMaterialPrizesModal';

const { SHOW_PARENT } = TreeSelect;
const { TextArea } = Input;
interface DataType {
  id: number;
  key: string;
  name: string;
  questionsAmount: number;
  isPublished: boolean;
  prize: string;
  access: number[],
}

const Materials: React.FC = () => {
  useEffect(() => { getData() }, [])
  const getData = () => {
    setIsLoading(true);
    materialListForAdmin(0).then(data => {
      setMaterials(data);
      setFullData(data);
      setIsLoading(false);
    });
    if ([3, 4].includes(user.role)) getManagerList(0).then(setManagerList);
    if (user.isAdmin) getAllStates().then(setStateList);
  }
  const [materials, setMaterials] = useState<any>([]);
  const [fullData, setFullData] = useState<any>([]);
  const [managerList, setManagerList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [selectedAccessStates, setSelectedAccessStates] = useState<number[]>([]);
  const [chosenManagerId, setChosenManagerId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMaterialAccess, setCurrentMaterialAccess] = useState<number | null>(null);
  const [currentMaterial, setCurrentMaterial] = useState<any>(null);
  const rejectionReason = useRef('')
  let { id = '0' } = useParams();
  const { user } = useAuth();
  useEffect(() => {
    rejectionReason.current = '';
  }, [currentMaterial])

  const handleDelete = (id: number) => {
    if (window.confirm('Вы уверены?')) deleteMaterial(id).then(() => {
      getData()
    })
  }

  const handlePublish = (id: number, status: boolean) => {
    publishMaterial(id, status).then(() => {
      getData()
    })
  }

  const onSavePrize = (materialId: number, managerId: string, prizes: string) => {
    const tempMaterials = [...materials];
    const currentMaterialIndex = tempMaterials.findIndex(x => x.id === materialId);
    managerId.split(',').forEach(mId => {
      tempMaterials[currentMaterialIndex].prize[mId] = { prizes: prizes, status: 0 };
    })
    setMaterials(tempMaterials);
  }

  const prizeStatus = ['На согласовании', 'Согласовано', 'Отказано'];
  const columns: ColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => <div>{text}{!!user.isAdmin && <Link style={{ float: 'right', fontSize: 18 }} to={'/admin/new-material/' + row.id}><EditOutlined /></Link>}</div>,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Вопросы',
      dataIndex: 'questionsAmount',
      key: 'questionsAmount',
      sorter: (a, b) => a['questionsAmount'] - b['questionsAmount'],
      render: (text, row) => <div>{text}{!!user.isAdmin && <Link style={{ float: 'right', fontSize: 18 }} to={'/admin/new-test/' + row.id}><EditOutlined /></Link>}</div>,
    },
    ...user.isAdmin ?
      [{
        title: 'Доступ',
        dataIndex: 'access',
        key: 'access',
        width: 220,
        render: (item: any, row: DataType) => <>
          {item?.[0] === 0 ? stateList.length : item?.length || 0} / {stateList.length}
          <Link style={{ float: 'right', fontSize: 18 }} to='#' onClick={() => { setCurrentMaterialAccess(row.id); setSelectedAccessStates(row.access) }}><EditOutlined /></Link>
        </>,
      },
      {
        title: '',
        key: 'id',
        render: (row: any) => <Row gutter={[0, 5]} style={{ maxWidth: 190, float: 'right' }}>
          <Col>
            <Col xs={24} sm={0}><span onClick={() => handlePublish(row.id, !row.isPublished)} className={row.isPublished ? 'red-badge' : 'green-badge'}>{row.isPublished ? 'Блок' : 'Разб'}</span></Col>
            <Col xs={0} sm={24}><span onClick={() => handlePublish(row.id, !row.isPublished)} className={row.isPublished ? 'red-badge' : 'green-badge'}>{row.isPublished ? 'Заблокировать' : 'Опубликовать'}</span></Col>
          </Col>
          <Col><span onClick={() => handleDelete(row.id)} style={{ color: '#A40207', cursor: 'pointer', marginLeft: 15 }}><DeleteOutlined style={{ fontSize: 18 }} /></span></Col>
        </Row>
      }] : [],
    ...user.role === 4 ?
      [{
        title: 'Цены',
        dataIndex: 'prize',
        key: 'prize',
        width: 220,
        render: (text: any, row: DataType) => <>
          {text[chosenManagerId]?.prizes ?? text[0]?.prizes}
          <EditMaterialPrizesModal
            materialId={row.id}
            managerId={chosenManagerId ? String(chosenManagerId) : managerList.map((x: any) => x.id).join()}
            prizes={text[chosenManagerId]?.prizes ?? text[0]?.prizes}
            onSavePrize={onSavePrize}
          />
        </>,
      },
      {
        title: 'Статус цены',
        dataIndex: 'prize',
        key: 'prize',
        width: 220,
        render: (text: any, row: DataType) => {
          if (text[chosenManagerId] && chosenManagerId > 0) {
            const status = text[chosenManagerId].status;
            if (status === 2) return <Tooltip title={text[chosenManagerId].reason}><span style={{ color: '#A40207', borderBottom: '1px dashed #A40207' }}>{prizeStatus[text[chosenManagerId].status]}</span></Tooltip>
            else return prizeStatus[text[chosenManagerId].status];
          } else return '-'
        }
      }] : [],
    ...user.role === 3 ?
      [{
        title: 'Цены',
        dataIndex: 'prize',
        key: 'prize',
        render: (item: any, row: DataType) => {
          const onApprovalPrizesArr: any[] = Object.values(item).filter((x: any) => (x.status === 0 && (!chosenManagerId || !x?.manager || x?.manager?.id === chosenManagerId)));
          if (onApprovalPrizesArr?.length > 1) return <>
            <div>{onApprovalPrizesArr.length - 1}: {onApprovalPrizesArr[1].manager?.state?.name}, {onApprovalPrizesArr[1]?.manager?.name} </div>
            <span style={{ color: 'red' }}>{onApprovalPrizesArr[0].prizes}</span> <ArrowRightOutlined /> <span style={{ color: 'green' }}>{onApprovalPrizesArr[1].prizes}</span>
            <div style={{ position: 'absolute', top: 25, right: 10 }}>
              <Link onClick={() => { handlePrize(1, row) }} style={{ fontSize: 18, marginRight: 5 }} to='#'><CheckOutlined /></Link>
              <Link onClick={() => setCurrentMaterial(row)} style={{ fontSize: 18 }} to='#'><CloseOutlined /></Link>
            </div> 
          </>
          return item[chosenManagerId]?.status === 1 ? item[chosenManagerId].prizes : item[0].prizes;
        }
      }] : [],
  ];

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    if (value.length > 2) setMaterials(fullData.filter((x: DataType) => x.name.toLowerCase().indexOf(value) >= 0))
    else setMaterials(fullData)
  }

  const handlePrize = (status: number, material: any = null) => {
    const handledMaterial = material || currentMaterial;
    const tempMaterials = [...materials];
    const currentMaterialIndex = tempMaterials.findIndex(x => x.id === handledMaterial.id);
    let currentPrizeIndex = chosenManagerId;
    if(!chosenManagerId)  {
      const temp = Object.values(tempMaterials[currentMaterialIndex].prize) as any[];
      currentPrizeIndex = temp.filter((x:any)=>!x.status)?.[1]?.manager?.id
    }
    handlePrizeRequest(handledMaterial.prize[currentPrizeIndex].id, status, rejectionReason.current);
    setCurrentMaterial(null);
    tempMaterials[currentMaterialIndex].prize[currentPrizeIndex].status = status;
    setMaterials(tempMaterials);
  }

  const handleAccess = () => {
    if (currentMaterialAccess) saveMaterialAccess(currentMaterialAccess, selectedAccessStates);

    const tempMaterials = [...materials];
    const currentMaterialIndex = tempMaterials.findIndex(x => x.id === currentMaterialAccess);
    tempMaterials[currentMaterialIndex].access = selectedAccessStates;
    setMaterials(tempMaterials);

    setCurrentMaterialAccess(null);
  }

  const defaultCurrent = Math.ceil((materials.findIndex((x: any) => x.id === Number(id)) + 1) / 10);
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
        <Col span={24} style={{ fontSize: 25 }}>
          Материалы
        </Col>
        {!!managerList.length &&
          <Col sm={8} xs={12}>
            <Select
              style={{ width: '100%', height: 35 }}
              defaultValue={0}
              options={[{ label: 'Все менеджеры', value: 0 }, ...managerList.map((x: any) => ({ value: x.id, label: x.name }))]}
              onChange={setChosenManagerId}
            />
          </Col>
        }
        <Col sm={16} xs={12}><Input style={{ height: 35 }} onChange={handleSearch} placeholder='Поиск по названию (мин 3 символа)' /></Col>
        {!!user.isAdmin && <Col sm={8} xs={24}><Link to={'/admin/new-material'}><Button style={{ width: '100%' }}> Новый материал <PlusOutlined /></Button></Link></Col>}
        {!!materials.length && <Col span={24}><Table columns={columns} dataSource={materials} pagination={{ defaultCurrent: defaultCurrent > 0 ? defaultCurrent : 1 }} /></Col>}
      </Row>
      <Modal title={'Уверены что хотите отклонить?'} open={!!currentMaterial} onOk={() => handlePrize(2)} onCancel={() => setCurrentMaterial(null)}>
        <TextArea rows={4} onChange={(e) => rejectionReason.current = e.target.value} />
      </Modal>
      <Modal title={'Редактирование доступов'} open={!!currentMaterialAccess} onOk={() => handleAccess()} onCancel={() => setCurrentMaterialAccess(null)}>
        <TreeSelect
          treeCheckable={true}
          allowClear
          showCheckedStrategy={SHOW_PARENT}
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={selectedAccessStates}
          onChange={(value) => setSelectedAccessStates(value)}
          treeData={[{
            title: 'Все филиалы',
            value: 0,
            key: 0,
            children: stateList.map((x: any) => ({ label: x.name, value: x.id }))
          }]}
          treeDefaultExpandAll
        />
      </Modal>
    </Spin>
  );
};

export default Materials;
